import type { BroadcastMasksState } from "src/features/broadcastMasks/exports/state";
import { ChatState } from "chat/exports/state/reducer";
import { type ChatGiftAnimationState } from "chat/giftAnimation/export";
import { PremiumMessageState } from "chat/premiumMessage/exports/types";
import { AppealState } from "src/features/appeal/AppealPage/exports/state";
import { AutoLoginState } from "src/features/autoLogin/exports/state";
import { InvitationState } from "src/features/broadcast/exports/state";
import { BroadcastExternalState } from "src/features/broadcastExternal/exports/types";
import { CustomGiftState } from "src/features/customGift/exports/state";
import { FeedState } from "src/features/feed/exports/state/types";
import { RedeemConfigState } from "src/features/finance/redeems/state/redeemConfigSlice";
import { GifterUsersListState } from "src/features/gifterStatistics/exports/types";
import { GiftDrawerState } from "src/features/giftsDrawer/exports/state";
import { HomePageStreamsState } from "src/features/homePage/shared/streamsState/streamsSlice";
import { HomePageV2NearbyState } from "src/features/homePage/v2/state/nearbySlice";
import { LoyaltyProgramState } from "src/features/loyaltyProgram/exports/state";
import { MarketingState } from "src/features/marketing/exports/state";
import { MosState } from "src/features/mos/exports/types";
import { WelcomePremiumOfferState } from "src/features/offers/premiumEntrance/welcomePremiumOffer/state/slice";
import { PhoneNumberSettingsState } from "src/features/profile/settings/state/phoneNumberSettingsSlice";
import { ProfileSettingsState } from "src/features/profile/settings/state/profileSettingsSlice";
import { VisitorProfileState } from "src/features/profile/visitorProfile/exports/state";
import { BottomSheetState } from "src/features/promotions/promoBottomSheet/state/bottomSheetSlice";
import { ReferralProgramState } from "src/features/referralProgram/exports/state";
import { LoginState } from "src/features/signin/state/login/types";
import { TangoAcademyState } from "src/features/tangoAcademy/exports/state";
import {
  SocialGamesFreeSpinsState,
  SocialGamesGameState,
  SocialGamesInStreamLobbyState,
  SocialGamesLobbyState,
} from "src/features/socialGames/exports/state";
import { VisitorCashierState } from "src/features/visitorCashier/state/slice";
import { WelcomeOnboardingOfferState } from "src/features/welcomeOnboardingOffer/state/welcomeOfferSlice";
import { BlockedUsersState } from "src/state/tree/blockedUsers";
import { CountriesState } from "src/state/tree/countries";
import { PointsState } from "src/state/tree/pointsSlice";
import { TournamentsLandingState } from "src/state/tree/tournamentsLanding";
import { BannerConfigsState } from "src/types/banner";
import { ShowRoomStream } from "src/types/common";
import { AfterPurchaseTaskManagerState } from "state/tree/afterPurchaseTaskManager";
import { BonusWheelState } from "state/tree/bonusWheel";
import { BroadcastState } from "state/tree/broadcast";
import { CaptchaState } from "state/tree/captcha";
import { ConfigurationState } from "state/tree/configuration";
import { CountryByIpState } from "state/tree/countryByIp";
import { CurrenciesState } from "state/tree/currencies";
import { GuardianState } from "state/tree/guardian";
import { CustomizationsState } from "state/tree/homePageV1/customizations/customizationsSlice";
import { HomePageV1State } from "state/tree/homePageV1/types";
import { LandingPagePhoenixState } from "state/tree/landingPagePhoenix";
import { LeaderBoardState } from "state/tree/leaderBoardLanding";
import { NavigationState } from "state/tree/navigation";
import { PersonalOffersState } from "state/tree/personalOffers";
import { PipsConfigState } from "state/tree/pipsConfig";
import { ReceivedAcmeIdsType } from "state/tree/receivedAcmeIds";
import { SessionDetailsState } from "state/tree/sessionDetails";
import { StoriesState } from "state/tree/stories";
import { SubscriptionPerStreamerListState } from "state/tree/subscriptionPerStreamerList";
import { SubscriptionsPerStreamerState } from "state/tree/subscriptionsPerStreamer";
import { TaskManagerState } from "state/tree/taskManager";
import { TCNNState } from "state/tree/tcnn";
import { UserSearchState } from "state/tree/userSearch";
import { ValidationState } from "state/tree/validation";
import { VipConfigsState } from "state/tree/vipConfigs";
import { VisitorState } from "state/tree/visitor";
import {
  DeviceInfoState,
  IntercomState,
  NearbyCountriesState,
  SuspensionStatusState,
  TabsConfigState,
  TaxesState,
} from "state/types";
import { GiftsCacheState } from "./tree/giftsCache.type";

export interface RootState {
  afterPurchaseTaskManager: AfterPurchaseTaskManagerState;
  appeal: AppealState;
  autoLogin: AutoLoginState;
  bannerConfigs: BannerConfigsState;
  blockedUsers: BlockedUsersState;
  bonusWheel: BonusWheelState;
  bottomSheet: BottomSheetState;
  broadcast: BroadcastState;
  broadcastExternal: BroadcastExternalState;
  broadcastMasks: BroadcastMasksState;
  captcha: CaptchaState;
  chat: ChatState;
  chatGiftAnimation: ChatGiftAnimationState;
  configuration: ConfigurationState;
  countries: CountriesState;
  countryByIp: CountryByIpState;
  currencies: CurrenciesState;
  customGift: CustomGiftState;
  customizations: CustomizationsState;
  deviceInfo: DeviceInfoState;
  feed: FeedState;
  giftDrawer: GiftDrawerState;
  gifterUsersList: GifterUsersListState;
  giftsCache: GiftsCacheState;
  guardian: GuardianState;
  homePageStreams: HomePageStreamsState;
  homePageV1: HomePageV1State;
  homePageV2Nearby: HomePageV2NearbyState;
  intercom: IntercomState;
  invitations: InvitationState;
  landingPagePhoenix: LandingPagePhoenixState;
  leaderBoardLanding: LeaderBoardState;
  login: LoginState;
  loyaltyProgram: LoyaltyProgramState;
  marketing: MarketingState;
  mos: MosState;
  mosChatV2: MosState;
  navigation: NavigationState;
  nearbyCountries: NearbyCountriesState;
  personalOffers: PersonalOffersState;
  phoneNumberSettings: PhoneNumberSettingsState;
  pipsConfig: PipsConfigState;
  pointsUserInfo: PointsState;
  premiumMessage: PremiumMessageState;
  profileSettings: ProfileSettingsState;
  receivedAcmeIds: ReceivedAcmeIdsType;
  redeemConfig: RedeemConfigState;
  referralProgram: ReferralProgramState;
  sessionDetails: SessionDetailsState;
  showRoomStreams: ShowRoomStream[];
  socialGamesFreeSpins: SocialGamesFreeSpinsState;
  socialGamesGame: SocialGamesGameState;
  socialGamesInStreamLobby: SocialGamesInStreamLobbyState;
  socialGamesLobby: SocialGamesLobbyState;
  stories: StoriesState;
  subscriptionPerStreamerList: SubscriptionPerStreamerListState;
  subscriptionsPerStreamer: SubscriptionsPerStreamerState;
  suspensionStatus: SuspensionStatusState;
  tabsConfig: TabsConfigState;
  tangoAcademy: TangoAcademyState;
  taskManager: TaskManagerState;
  taxes: TaxesState;
  tcnn: TCNNState;
  tournamentsLanding: TournamentsLandingState;
  userSearch: UserSearchState;
  validation: ValidationState;
  vipConfigs: VipConfigsState;
  visitor: VisitorState;
  visitorCashier: VisitorCashierState;
  visitorProfile: VisitorProfileState;
  welcomeOnboardingOffer: WelcomeOnboardingOfferState;
  welcomePremiumOffer: WelcomePremiumOfferState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

type MappedSelector<
  Selectors extends Record<string, unknown>,
  SelectorsKeys extends keyof Selectors,
> = (
  state: RootState,
  ...params: Parameters<OmitFirstArg<Selectors[SelectorsKeys]>>
) => ReturnType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Selectors[SelectorsKeys] extends (...args: any) => any
    ? Selectors[SelectorsKeys]
    : () => unknown
>;

export const mapSelectors = <
  Selectors extends Record<string, unknown>,
  SelectorsKeys extends keyof Selectors,
  Mapper extends (
    v: Selectors[SelectorsKeys]
  ) => MappedSelector<Selectors, SelectorsKeys>,
>(
  obj: Selectors,
  mapper: Mapper
) => {
  const keys = Object.keys(obj) as SelectorsKeys[];

  type Result = {
    [Property in keyof Selectors]: MappedSelector<Selectors, Property>;
  };

  let result: Result = {} as Result;

  for (const key of keys) {
    result = {
      ...result,
      [key]: mapper(obj[key]),
    };
  }

  return result;
};

// TODO: JSDOC should be removed after full migration to ts

/**
 * @template Selectors
 * @param {Selectors} selectors
 * @param key string
 * @return {Selectors}
 */

const delegateSelectors = <
  Selectors extends Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: RootState[Key], ...args: any[]) => any
  >,
  Key extends keyof RootState,
>(
  selectors: Selectors,
  key: Key
) =>
  mapSelectors(
    selectors,
    (selector) =>
      (state, ...rest) =>
        // @ts-ignore
        selector(state[key as keyof typeof state], ...rest)
  );

export default delegateSelectors;
